import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import './index.scss';

import CLOSE_ICON from '../../../images/icons/close.png';
// Style
const modalStyle = {
  content: {
    top: '54%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    borderColor: 'black',
    backgroundColor: 'black',
    // position: 'relative',
  },
};

Modal.setAppElement('#___gatsby');

const ModalComponent = ({ children, isOpen, setIsOpen, title }) => {
  /* ******************************** RENDERING ******************************* */
  return (
    <Modal
      overlayClassName="modal"
      bodyOpenClassName="disable-scroll"
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      style={modalStyle}
    >
      <div className="modal-content">
        <button
          type="button"
          className="close-modal"
          onClick={() => setIsOpen(false)}
        >
          <img
            className="close-icon"
            style={{ width: '10px' }}
            src={CLOSE_ICON}
            alt="close"
          />
        </button>

        {title ? <h3>{title}</h3> : <></>}
        {children}
      </div>
    </Modal>
  );
};

ModalComponent.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  title: PropTypes.string,
};

ModalComponent.defaultProps = {
  title: null,
};

export default ModalComponent;
